@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap');



body,html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  background: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Work Sans', sans-serif !important;
}

.stepper {
  display:none;
}


.font-body {
  font-size: 18px; 
}

/* Navbar.css */
.profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

#chatwoot-launcher {
  display: none !important;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 8px;
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  min-width: 100px;
}

.dropdown button {
  background-color: transparent;
  border: none;
  color: #333;
  cursor: pointer;
}

.profile:hover .dropdown {
  display: block;
}


.logout-button {
  color: red;
}


.bg-image {
  background-image: url('assets/preview_bg.png'); /* Replace with the actual path to your image */
  background-size: 100% auto; /* Stretch the image to the width of the div */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center; /* Center the image vertically */
}

.artboard {
  padding: 1rem; /* Add padding to create an inset for the stroke-box */
}

.stroke-box {
  border: 1px solid #FF7F6E; /* Set the border width, style, and color */
  padding: 1rem; /* Add padding to the content inside the stroke box */
  width: 100%; /* Set the width to 100% of the parent container */
  height: 100%; /* Set the height to 100% of the parent container */
}

.parent-container {
  position: relative; /* Set the position property to relative */
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Create a 3 column grid with equal width columns */
  gap: 1rem; /* Set the gap between the grid items */
}


.center-container {
  position: absolute; /* Set the position property to absolute */
  top: 60%; /* Position the center-container at the top of the parent-container */
  left: 0; /* Position the center-container at the left of the parent-container */
  width: 100%; /* Set the width to 100% of the parent-container */
  height: 100%; /* Set the height to 100% of the parent-container */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Set a z-index value to control the stacking order */
}

.video-container {
  width: 70%; /* Adjust the width according to your preference */
  margin: 0 auto; /* Center the video */
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  border-radius: 0rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  overflow: hidden; /* To apply border-radius to the iframe */
}



.App-header {
  width: 100%;
  background-color: white;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0);
  border-radius: 1rem;
}


.form-group {
  display: flex;
  flex-direction: column;
}

.stepper.active {
  display:block;
}

.btn-disabled {
  opacity: 0.5;
  pointer-events: none;
}


.btn {
  background-color: #FF7F6E;
  border: 0px solid gray;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  font-family: "Work Sans";
  font-size: 16px;
  text-align: center;
}

.btn-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-padded {
  padding-top: 8px; /* Add padding to the top of the button */
  padding-bottom: 8px; /* Add padding to the bottom of the button */
}

.btn.btn-outline {
  background-color: transparent;
  border: 2px solid #FF7F6E;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  color: #FF7F6E;
  font-family: "Work Sans";
  font-size: 16px;
}

.btn-main-text {
  /* Styles for the main text (if needed) */
  font-size: 18px; /* Adjust the font size as needed */
  color: #ffffff; 
}

.btn-subtext {
  /* Styles for the subtext */
  padding-top: 4px; /* Add padding to the top of the subtext */
  font-size: 12px; /* Adjust the font size as needed */
  color: #ffffff; /* Adjust the color as needed */
  font-weight: 400;
}

.component-container {
  width: 920px; /* Set the desired width here */
  max-width: 100%; /* Ensure the width does not exceed the parent container */
}


.btn.selected {
  background-color: #FF7F6E;
  color: white;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  max-height: 36px;
  max-width: 100%;
  object-fit: contain;
}


