/* Feed.module.css */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  padding: 20px;
  max-width: 800px;
}

.modalStep {
  background-color: #FBFBFB;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  margin: 0 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modalStepOrdered {
  border-color: #FF7F6E;
  border-width: 3px;
  border-style: solid;
}

.modalStepShipped {
  border-color: #FF7F6E;
}
